import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import logo_img from "../images/LVDV_logo.svg";

const MentionsLegales = () => (
  <Layout>
    <div className="bg-[url('../images/les-vitrines-de-valerie.jpg')] min-h-screen bg-cover bg-center flex items-center flex-col justify-center align-items-center relative">
      <div className="z-10 flex items-center flex-col justify-center align-items-center">
        <img src={logo_img} alt="Logo LVDV - Location de vitrines en France, en Belgique et au Luxembourg" />
        <h1 className="text-5xl md:text-7xl text-white my-12 text-center">Mention légales</h1>
      </div>
      
      <div className="bottom-0 absolute md:-rotate-90 right-0 md:bottom-16 z-10">
        <p className="text-white/36 m-0">Photo vitrine</p>
        <p className="text-white m-0">Bouisset Bernard</p>
      </div>
      <div className="voile w-full h-full absolute pointer-events-none bg-black/50"></div>
    </div>
    
    
    <div className="bg-produits px-5 py-10 md:px-9 ">
      <p className="mx-auto text-white">Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des utilisateurs et visiteurs, ci-après l"Utilisateur", du site lvdbv.fr , ci-après le "Site", les présentes mentions légale.</p>
      <p className="mx-auto text-white">La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale et sans réserve des présentes mentions légales.</p>
      <p className="mx-auto text-white">Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».</p>
      
      <h2 className="text-white text-4xl uppercase mb-10 text-center">ARTICLE 1 - L'EDITEUR</h2>
      <p className="mx-auto text-white">L’édition et la direction de la publication du Site est assurée par Maxence Lassus, domiciliée au 64 avenue Pasteur - 69370 Saint Didier au mont d'or, dont le numéro de téléphone est +336.66.55.44.49, et l'adresse e-mail imax3d.createur@gmail.com.</p>
      <p className="mx-auto text-white">La personne est assujettie au Registre du Commerce et des Sociétés de Lyon sous le numéro RCS 519 742 159.
      ci-après l'"Editeur".</p>
      
      <h2 className="text-white text-4xl uppercase mb-10 text-center">ARTICLE 2 - L'HEBERGEUR</h2>
      <p className="mx-auto text-white">L'hébergeur du Site est la société Maxence Lassus, dont le siège social est situé au 64 Avenue Pasteur - 69370 Saint Didier au mont d'or , est +336.66.55.44.49, et l'adresse e-mail imax3d.createur@gmail.com.</p>
      
      <h2 className="text-white text-4xl uppercase mb-10 text-center">ARTICLE 3 - ACCES AU SITE</h2>
      <p className="mx-auto text-white">Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découlant d’une nécessité de maintenance.</p>
      <p className="mx-auto text-white">En cas de modification, interruption ou suspension du Site, l'Editeur ne saurait être tenu responsable.</p>
      
      <h2 className="text-white text-4xl uppercase mb-10 text-center">ARTICLE 4 - COLLECTE DES DONNEES</h2>
      
      <p className="mx-auto text-white">Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune donnée concernant les utilisateurs.</p>
      
      <p className="mx-auto text-white">Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du Site, sans autorisation de l’Editeur est prohibée et pourra entraînée des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.</p>
    </div>
  </Layout>
)

export const Head = () => <Seo title="Mentions légales" />

export default MentionsLegales
